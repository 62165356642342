import React from "react";
import card from "./card.json";
import "./card.scss";
import Cardrumah from "../../../component/cardrumah/cardrumah.jsx";
import viola from "../../../asset/viola/viola.js";
import tera from "../../../asset/tera/tera.js";
import asiatropis from "../../../asset/asiatropis/asiatropis.js";
import nismara from "../../../asset/nismara/nismara.js";
import nishi from "../../../asset/nishi/nishi.js";
import elora from "../../../asset/elora/elora.js";
import lavesh from "../../../asset/lavesh/lavesh.js";
import curtina from "../../../asset/curtina/curtina.js";
import jasmia from "../../../asset/Jasmia/index.js";
import vanica from "../../../asset/vanica/vanica.js";
import hoshi from "../../../asset/hoshi/hoshi.js";

const imageMap = {
  viola,
  tera,
  nishi,
  nismara,
  elora,
  asiatropis,
  lavesh,
  curtina,
  jasmia,
  vanica,
  hoshi,
  // Add other image arrays here if you have more sets
};

const Card = () => {
  return (
    <div id="rumah" className="card">
      <div className="container-card">
        <div className="cardhome-container">
          {card.cards.map((card, index) => {
            // Determine the logo dynamically
            return (
              <Cardrumah
                key={index}
                carousel={imageMap[card.imagesKey]}
                title={card.title}
                subtitle={card.subtitle}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink}
                sitelinks={card.sitelinks} // Pass the WhatsApp link
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Card;
