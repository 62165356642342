import React from "react";
import "./About.scss";
import logo from "../../asset/main/logo (1).webp";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import mrthi from "./mrthi.png";
const About = () => {
  const wanavbar = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=628179807998&text=Halo%20Marketing%20Harapan%20Indah,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingharapanindah.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const text =
    "Kota Harapan Indah adalah area pemukiman di antara Cakung, Jakarta Timur, dan Pejuang, Kecamatan Medan Satria, Bekasi Utara, dan Kecamatan Tarumajaya, Kabupaten Bekasi. Kawasan seluas 2.200 hektar ini memiliki supermarket, sekolah, klub olahraga, dan banyak gedung perkantoran. Ada banyak fasilitas di Harapan Indah, seperti Ace Hardware, Santika Premier Hotel, XXI, Lotte Mart, Universitas Esa Unggul, Pasar Modern Harapan Indah, Sekolah BPK Penabur, Sekolah Al Azhar, Sekolah Cherry Montessori School, Transera Waterpark, Gramedia, EKA Hospital Harapan Indah, RS Citra Harapan, Klub Olahraga Harapan Indah, Apartemen, dan Living Plaza. Salah satu sarana transportasi umum yang dapat diakses adalah TransJakarta, yang mengoperasikan BRT dari Harapan Indah dengan nama trayek 2B. Halte busnya terletak di beberapa lokasi di dalam kompleks.";

  return (
    <div className="aboutcontainer">
      <div className="aboutcenter">
        <div className="aboutlogo">
          <img className="logo" src={logo} alt="kotaharapanindah" />
          <div className="logodes">Timur Jakarta, Bekasi</div>
        </div>
        <div className="about">
          <div className="abouttitle">Kawasan Mandiri Kota Harapan Indah</div>
          <div className="logodes">Timur Jakarta, Bekasi</div>
          <div className="logoprice">Rp. 2 Juta - 16 Juta</div>
          <div className="aboutsubtitle">
            <ReactReadMoreReadLess
              charLimit={284}
              readMoreText={"Read more ▼"}
              readLessText={"Read less ▲"}
            >
              {text}
            </ReactReadMoreReadLess>
          </div>
          <div className="buttonwa">
            <button onClick={wanavbar} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp;&nbsp; Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div className="future">
        <div className="judul-futur">MRT AKAN HADIR DI HARAPAN INDAH</div>
        <div className="image">
          <img src={mrthi} alt="MRT-Bekasi" />
        </div>
      </div>
    </div>
  );
};

export default About;
