import Vanica1 from "./vanica (1).png";
import Vanica2 from "./vanica (2).png";
import Vanica3 from "./vanica (3).png";
import Vanica4 from "./vanica (4).png";
import Vanica5 from "./vanica (5).png";
import Vanica6 from "./vanica (6).png";
import Vanica7 from "./vanica (7).png";
import Vanica8 from "./vanica (8).png";
import Vanica9 from "./vanica (9).png";
import Vanica10 from "./vanica (10).png";
import Vanica11 from "./vanica (11).png";
import Vanica12 from "./vanica (12).png";
import Vanica13 from "./vanica (13).png";

const vanica = [
  Vanica1,
  Vanica2,
  Vanica3,
  Vanica4,
  Vanica5,
  Vanica6,
  Vanica7,
  Vanica8,
  Vanica9,
  Vanica10,
  Vanica11,
  Vanica12,
  Vanica13,
];

export default vanica;
