import Hoshi1 from "./hoshi (1).webp";
import Hoshi2 from "./hoshi (2).webp";
import Hoshi3 from "./hoshi (3).webp";
import Hoshi4 from "./hoshi (4).webp";
import Hoshi5 from "./hoshi (5).webp";
import Hoshi6 from "./hoshi (6).webp";
import Hoshi7 from "./hoshi (7).webp";
import Hoshi8 from "./hoshi (8).webp";
import Hoshi9 from "./hoshi (9).webp";
import Hoshi10 from "./hoshi (10).webp";

const hoshi = [
  Hoshi1,
  Hoshi2,
  Hoshi3,
  Hoshi4,
  Hoshi5,
  Hoshi6,
  Hoshi7,
  Hoshi8,
  Hoshi9,
  Hoshi10,
];

export default hoshi;
